import Head from 'next/head';
import React from 'react';
import getConfig from 'next/config';

/**
 * Atom PageMetaContent
 *
 * <!-- TODO: add a description here! -->
 */
function PageMetaContent(props) {
  const fullpath = props?.fullpath ?? '/';
  const { title, description, is404 = false } = props;

  const { publicRuntimeConfig } = getConfig();

  const canonicalURL =
    fullpath === '/' ? `${publicRuntimeConfig?.SERVER_ADDR}` : `${publicRuntimeConfig?.SERVER_ADDR}${fullpath}`;

  return (
    <>
      <Head>
        <link rel="icon" href="/images/favicon.png" />

        {publicRuntimeConfig.ENV_TYPE !== 'PRD' && <meta name="robots" content="noindex, nofollow" />}

        {title && (
          <>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
          </>
        )}
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="twitter:description" content={description} />
          </>
        )}
        {canonicalURL && !is404 && (
          <>
            <link rel="canonical" href={canonicalURL} />
            <meta property="og:url" content={canonicalURL} />
            <meta property="twitter:url" content={canonicalURL} />
          </>
        )}
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.toyota.com.br/images/logos/logo-toyota.svg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="https://www.toyota.com.br/images/logos/logo-toyota.svg" />
      </Head>
    </>
  );
}

export default PageMetaContent;
